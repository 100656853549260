import React, { useCallback, useState, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import './Nav.scss'
import { slide as Menu } from 'react-burger-menu'
import {
  ScrollingProvider,
  useScrollSection,
  Section,
} from 'react-scroll-section';
 

const Nav: React.FC = () => {
  const homeSection = useScrollSection('home');
  const featuresSection = useScrollSection('features');
  const communitySection = useScrollSection('community');

  const showSettings = (event) => {
    event.preventDefault();
  }
   
  return (
    <StyledNav>
      <li>
        <StyledLink onClick={homeSection.onClick} selected={homeSection.selected}>
          Home
        </StyledLink>
      </li>
      <li>
        <StyledLink onClick={featuresSection.onClick} selected={featuresSection.selected}>
          Features
        </StyledLink>
      </li>
      <li>
        <StyledLink onClick={communitySection.onClick} selected={communitySection.selected}>
          Community
        </StyledLink>
      </li>
    </StyledNav>
      )
}

const StyledNav = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  flex: 1;
  @media (max-width: 768px) {
    /*display: none;*/
  }

  & li {
    padding: 0;
  }

  
`

const StyledLink = styled.span<{selected: any}>`
  color: ${props => (!props.selected ? props.theme.color.white : '#FFB000')};
  border-bottom: ${props => (props.selected ? '1px solid #FFB000' : '')};
  font-weight: 700;
  padding-left: ${(props) => props.theme.spacing[2]}px;
  padding-right: ${(props) => props.theme.spacing[2]}px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.color.green[500]};
    border-bottom: 1px solid #FFFF00;
  }

  @media (max-width: 400px) {
    padding-left: 1px;
    padding-right: 1px;
  }
  font-size: 13px;

  @media (min-width: 480px) {
    font-size: 18px;
  }
`

const StyledAbsoluteLink = styled.a`
  color: ${(props) => props.theme.color.white};
  font-weight: 700;
  padding-left: ${(props) => props.theme.spacing[2]}px;
  padding-right: ${(props) => props.theme.spacing[2]}px;
  text-decoration: none;
  outline: none;
  &:hover {
    color: ${(props) => props.theme.color.green[500]};
  }
  &.active {
    color: ${(props) => props.theme.color.red[800]};
  }
  @media (max-width: 400px) {
    padding-left: ${(props) => props.theme.spacing[2]}px;
    padding-right: ${(props) => props.theme.spacing[2]}px;
  }
`

export default Nav
