import React, { useCallback, useState, useMemo } from 'react'
import styled from 'styled-components'

import Button from '../Button'
import Modal, { ModalProps } from '..//Modal'
import ModalActions from '..//ModalActions'
import ModalContent from '../ModalContent'
import ModalTitle from '../ModalTitle'
import MainContainer from '../MainContainer'

interface DisclaimerModal extends ModalProps {
  onConfirm: () => void
}

const StyledText = styled.div`
  color: ${(props) => props.theme.color.white};
  font-size: 16px;
  text-decoration: none;
  }
`

const StyledLink = styled.a`
  color: ${(props) => props.theme.color.grey[400]};
  padding-left: 25%;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.color.grey[500]};
  }
`

const StyledContainer = styled.div`
  text-align: center;
`

const DisclaimerModal: React.FC<DisclaimerModal> = ({
  onConfirm,
  onDismiss,
}) => {
  const [step, setStep] = useState('disclaimer')

  const handleConfirm = useCallback(() => {
    onConfirm()
    onDismiss()
  }, [onConfirm, onDismiss])

  const modalContent = useMemo(() => {
    if (step === 'disclaimer') {
      return (
         <StyledContainer>
          <StyledText>
            Welcome to Gielinor!
          </StyledText>
          <StyledText>
            This is an OSRS inspired community project.
          </StyledText>
          <StyledText>
          Disclaimer: This project is not affiliated with or sponsored by Jagex Ltd. or RuneScape in any way.
          </StyledText>
         </StyledContainer>
         
      )
    } else {
      return (
        <StyledContainer>
          <StyledText>
            Enjoy reliving the nostalgia.
          </StyledText>
        </StyledContainer>
      )
    }
  }, [step])

  const button = useMemo(() => {
    if (step === 'disclaimer') {
      return (
        <Button
          width={`40%`}
          text="Accept"
          onClick={() => setStep('pancakeswap')}
        />
      )
    } else {
      return <Button width={`40%`} text="Enter" onClick={handleConfirm} />
    }
  }, [setStep, step, handleConfirm])

  return (
    <Modal>
      <MainContainer title={`Disclaimer`} width={`none`}> 
        <ModalContent>{modalContent}</ModalContent>
        <ModalActions>{button}</ModalActions>
      </MainContainer>
    </Modal>
  )
}

export default DisclaimerModal
