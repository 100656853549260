import React from 'react'
import Logo from '../../assets/img/LatestRSGP.png'
import './RSGPfinanceIconBrand.scss';

interface RSGPfinanceIconBrand {
  icon: string
}

const RSGPfinanceIconBrand: React.FC<RSGPfinanceIconBrand> = ({ icon }) => {
  return (
    <>
      <img id="logo__mask" src={Logo} width="120px"/>
    </>
  );
}

export default RSGPfinanceIconBrand;
