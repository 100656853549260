import React, { useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'

import { Link } from 'react-router-dom'

interface ButtonProps {
  children?: React.ReactNode,
  disabled?: boolean,
  href?: string,
  onClick?: () => void,
  size?: 'sm' | 'md' | 'lg',
  text?: string,
  to?: string,
  variant?: 'default' | 'secondary' | 'tertiary' | 'deposit',
  btnType?: string,
  width?: string,
  border?: string,
  backgroundColor?: string
}

const Button: React.FC<ButtonProps> = ({
  children,
  disabled,
  href,
  onClick,
  size,
  text,
  to,
  variant,
  btnType,
  width,
  border,
  backgroundColor=undefined
}) => {
  const { color, spacing } = useContext(ThemeContext)

  let buttonColor: string
  switch (variant) {
    case 'secondary':
      buttonColor = color.grey[1000]
      break
    case 'deposit':
      buttonColor = `rgba(0, 255, 133, 0.5)`;
      break
    case 'default':
    default:
      buttonColor = color.blue[500]
  }

  let buttonSize: number
  let buttonPadding: number
  let fontSize: number
  switch (size) {
    case 'sm':
      buttonPadding = spacing[3]
      buttonSize = 36
      fontSize = 16
      break
    case 'lg':
      buttonPadding = spacing[4]
      buttonSize = 72
      fontSize = 16
      break
    case 'md':
    default:
      buttonPadding = spacing[4]
      buttonSize = 30
      fontSize = 18
  }

  const ButtonChild = useMemo(() => {
    if (to) {
      return <StyledLink to={to}>{text}</StyledLink>
    } else if (href) {
      return <StyledExternalLink href={href} target="__blank">{text}</StyledExternalLink>
    } else {
      return text
    }
  }, [href, text, to])

  return (
    <StyledButton
      color={buttonColor}
      disabled={disabled}
      fontSize={fontSize}
      onClick={onClick}
      padding={buttonPadding}
      size={buttonSize}
      btnType={btnType}
      width={width}
      border={border}
      backgroundColor={backgroundColor}
    >
      {children}
      {ButtonChild}
    </StyledButton>
  )
}

interface StyledButtonProps {
  color: string,
  disabled?: boolean,
  fontSize: number,
  padding: number,
  size: number,
  btnType : string,
  width: string,
  border: string,
  backgroundColor: string
}

const StyledButton = styled.button<StyledButtonProps>`
  align-items: center;
  background-color: ${props => props.btnType != "select" ? 
                                                          props.backgroundColor != undefined ? props.backgroundColor : props.theme.color.red[800]
                                                         : props.theme.color.grey[1000]};
  border-radius: 1px;
  border: 1px solid rgba(5, 5, 4, 1);
  box-shadow: 2px 2px 10px 0px rgb(96 86 74 / 80%) inset, -2px -2px 2px 0px rgb(0 0 0 / 60%) inset;
  color: rgba(255, 176, 0, 1);
  cursor: pointer;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
  display: flex;
  font-size: ${props => props.fontSize}px;
  height: ${props => props.size}px;
  justify-content: center;
  outline: none;
  padding-left: ${props => props.padding}px;
  padding-right: ${props => props.padding}px;
  pointer-events: ${props => !props.disabled ? undefined : 'none'};
  width: ${props => props.width != undefined ? props.width : `auto` };//100%;
  &:hover {
    //background-color: ${props => props.theme.color.grey[100]};
    background-color: ${props => props.theme.color.grey[600]};
  }
  & .buy {
    //background-color: ${props => props.theme.color.grey[100]};
    background-color: ${props => props.theme.color.green[500]};
  }
  &:focus {
    outline: none;
  }
`

const StyledLink = styled(Link)`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 25px;
  justify-content: center;
  margin: 0 ${props => -props.theme.spacing[4]}px;
  padding: 0 ${props => props.theme.spacing[4]}px;
  text-decoration: none;
`

const StyledExternalLink = styled.a`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 25px;
  justify-content: center;
  margin: 0 ${props => -props.theme.spacing[4]}px;
  padding: 0 ${props => props.theme.spacing[4]}px;
  text-decoration: none;
`

export default Button
