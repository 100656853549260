export const white = '#FFF'
export const black = '#000'

export const text = {
  100: 'rgba(255, 176, 0, 1)',
}

export const blue = {
  100: '#00FDff',
  200: '#00c2ff',
  300: '#00c3ff',
  //500: '#03f4ff',
  500: '#ffffff',
  700: '#0000ff',
}

export const green = {
  100: '#00FDFE',
  200: '#00c2a8',
  500: 'rgba(255, 255, 0, 1)', // bright yellow text color
}
export const red = {
  100: '#FFFDFE',
  200: '#ffc2a8',
  500: '#110000',
  800: '#494034', //backgroundcolor buttons
}

export const grey = {
  100: '#f7f4f2',
  200: 'rgba(62, 53, 45, 1)', //background cards
  300: '#E3E3E3',
  400: '#aa9585',
  500: '#805e49',
  600: '#354847', //Shadow background
  700: 'rgba(141, 121, 101, 1)', //Cards border 1px solid rgba(141, 121, 101, 1)
  800: '#2d1e12',
  900: '#515151',
  1000: '#8F8F8F', // Footnote text color & button select
  1100: '#060606', // Modal background color darken
}
