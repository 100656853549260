import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import Logo from '../Logo'
import Nav from './components/Nav'
import RSGPfinanceIconBrand from '../RSGPfinanceIconBrand'

import './TopBar.scss';


const TopBar: React.FC = () => {
  return (
    <NavWrapper>
      <NavContainer>
       
        <RSGPfinanceIconBrand icon="" />
        <Nav />
        </NavContainer>
   
    </NavWrapper>
  )
}

const NavContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 3%;

  @media only screen and (min-width: 480px) {
    padding: 10px 7%;
  }

  @media only screen and (min-width: 768px) {
    padding: 10px 15%;
  }
`

const NavWrapper = styled.nav`
  position: fixed;
  background-color: rgb(14 13 10);;
  width: 100%;
  z-index: 10;
  border-bottom: 1px solid #413A35;
  box-shadow: #e29c11 0px -6px 15px;
`

export default TopBar;
