import React from 'react'
import styled from 'styled-components'
import Page from '../../components/Page'
import Twitter from '../../assets/img/twitter.svg'
import Discord from '../../assets/img/discordsvg.svg'
import Telegram from '../../assets/img/telegram.svg'
import MainContainer from '../../components/MainContainer'
import BinanceIcon from '../../assets/img/binance_logo.png'
import gambling from '../../assets/img/Chests.png'
import farming from '../../assets/img/farmboost.png'
import grandexchange from '../../assets/img/GrandExchange.png'
import { Section } from 'react-scroll-section';

import chainlinkLogo from '../../assets/img/chainlink_logo_white.png'
import coinsStacked from '../../assets/img/coins_stacked.png'
import purplePhat from '../../assets/img/purple_phat.png'
import openChest from '../../assets/img/open_sinister_chest.png'

interface FeaturesContentProps {
  image: any,
  description: any,
  rowDirection: string
}

const FeaturesContent: React.FC<FeaturesContentProps> = ({image, description, rowDirection}) => {
  return (
    <FeaturesContentWrapper rowDirection={rowDirection}>
      <FeatureImageWrapper>
        <img src={image}/>
      </FeatureImageWrapper>
      <FeatureTextWrapper rowDirection={rowDirection}>
        {description}
      </FeatureTextWrapper>
    </FeaturesContentWrapper>
  )
}

const FeatureTextWrapper = styled.div<{rowDirection: string}>`
  text-align: center;
  font-size: 18px;
  color: white;
  text-shadow: 1px 1px 0px rgb(0 0 0);
  font-family: 'RuneScape Plain 12', monocode;
  line-height: 1;
  align-self: center;
  margin-bottom: 20px;

  & span {
    color: #FFFF00;
    text-shadow: 0px 0px 30px #ffff004d;
  }

  @media only screen and (min-width: 480px) {
    text-align: ${(props) => (props.rowDirection == 'row' ? 'end' : 'start')};
    width: 430px;
    font-size: 20px;
    padding: 0px 6px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 24px;
  }
`

const FeatureImageWrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 50px;
  width: 35%;


  @media only screen and (min-width: 480px) {
    margin: unset;
  }
`

const FeaturesContentWrapper = styled.div<{rowDirection: string}>`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  justify-content: space-between;

  @media only screen and (min-width: 480px) {
    flex-direction: ${(props) => (props.rowDirection)};
    justify-content: space-evenly;
  }
`

interface SocialsBoxProps {
  image: any,
  url: any,
  platform?: string
}

const SocialsBox: React.FC<SocialsBoxProps> = ({image, url, platform}) => {
  const urlShortcut = url.substring(8);

  return (
    <SocialsBoxInner href={url} target="_blank">
      <SocialsContent>
        <SocialsTitle>{platform}</SocialsTitle>
        <IconBox>
          <img src={image} width="32px"/>
        </IconBox>
        <SocialsUrl>{urlShortcut}</SocialsUrl>
      </SocialsContent>
    </SocialsBoxInner>
  )
}

const SocialsContent = styled.div`
  display: flex;
  height: 120px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 10px 8px 10px 8px;
`

const SocialsBoxInner = styled.a`
  display: block;
  background: #27221E;
  border: 1px solid #413A35;
  width: 48%;

  @media only screen and (min-width: 480px) {
    width: 225px;
  }
`

const SocialsUrl = styled.span`
  font-family: 'RuneScape Plain 11', monocode;
  font-size: 16px;
  text-align: center;
  color: #FFFF00;
  text-shadow: 1px 1px 0px rgb(0 0 0);

  @media only screen and (min-width: 480px) {
    font-size: 20px;
  }
`

const SocialsTitle = styled.span`
  font-family: 'RuneScape Plain 12', monocode;
  font-size: 18px;
  text-align: center;
  color: white;
  text-shadow: 1px 1px 0px rgb(0 0 0);
`


interface BoxProps {
  image: any,
  description: any,
  stackedImage?: boolean
}

const Box: React.FC<BoxProps> = ({image, description, stackedImage}) => {
  return (
    <ContainerBox>
      <ContentBox>
        <IconBox>
          <img src={image} width={image == chainlinkLogo ? '150px': '45px'}/>
          {stackedImage && (
            <StackedImg>
              <img src={purplePhat} width="20px" />
            </StackedImg>
          )}
        </IconBox>
        <ContentBoxTextWrapper>
          <div>{description}</div>
        </ContentBoxTextWrapper>
      </ContentBox>
    </ContainerBox>
  )
}

const ContentBoxTextWrapper = styled.div`
  text-align: center;

  font-size: 21px;
  color: white;
  text-shadow: 1px 1px 0px rgb(0 0 0);
  font-family: 'RuneScape Plain 11', monocode;
  line-height: 1;

  & span {

  }

  & div {
    & span {
      color: #FFFF00;
    }
  }

  @media only screen and (min-width: 480px) {
    font-size: 20px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }
`
const StackedImg = styled.div`
  position: absolute;
  top: 3px;
  left: 7px;

  & img {
    filter: drop-shadow(0px 0px 6px #ad17f5);
  }
`

const IconBox = styled.div`
  position: relative;
`

const ContentBox = styled.div`
  display: flex;
  height: 140px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 10px 8px 10px 8px;
`

const ContainerBox = styled.div`
  width: 100%;
  background: #27221E;
  border: 1px solid #413A35;
  margin-bottom: 10px;

  @media only screen and (min-width: 590px) {
    width: 280px;
    margin-bottom: 0;
  }
`
const Home: React.FC = () => {
  return (
    <Page>
        <Section id="home">
          <MediaSectionTop>
            <MainContainer title={`DeFi Meets Gaming Nostalgia`}>
              <InnerContainer>
                <ContainerText>
                  <div><span>RSGP.finance</span> is an upcoming OSRS inspired, gamified NFT yield farming platform built on the Binance Smart Chain. Combining the power of blockchain and NFT’s with the nostalgia of the early 2000’s, you can earn <span>$RSGP</span> tokens by staking liquidity on our platform in the yield farms, open chests to receive (very rare) items in the form of <span>NFT’s</span> and more.</div>
                </ContainerText>
                <ContainerText>
                  <div>Obtain iconic rare and limited items, sell them on the Grand Exchange or keep the items to flex to your friends or the rest of the world. Each NFT has unique values that will boost the farming rewards and lower the locktime.</div>
                </ContainerText>
                <ButtonTogether>
                  <LinkedBtn href="https://t.me/RSGPfinance" target="_blank">Join our Telegram</LinkedBtn>
                  <LinkedBtn href="https://twitter.com/RSGPfinance" target="_blank">Follow our Twitter</LinkedBtn>
                  <LinkedBtn href="https://discord.gg/53xqK7Gfjf" target="_blank">Join our Discord</LinkedBtn>
                </ButtonTogether>
              </InnerContainer>
            </MainContainer>  
                  <OverviewSection>
              <SectionTitle>Overview</SectionTitle>
              <ContainerBoxWrapper>
                <Box image={chainlinkLogo} description={<><span>Chainlink VRF integration</span> for provably fair and tamper-proof randomness.</>}/>
                <Box image={coinsStacked} description={<>DeFi yield farming meets NFT gameplay mechanics. Earn  <span>$RSGP</span> and get rewarded rare NFT’s.</>}/>
                <Box image={openChest} description={<>Optimize your earnings with the various NFT’s earned from <span>opening chests or locking up</span> your $RSGP.</>} stackedImage={true}/>
                <Box image={BinanceIcon} description={<>Built on <span>Binance Smart Chain</span> for fast, secure, and low cost transactions.</>}/>
              </ContainerBoxWrapper>
            </OverviewSection>
          </MediaSectionTop>
        </Section>

    

        <Section id="features">
          <MediaSectionFullWidth>
            <SectionFeatures>
              <FeaturesWrapper>
                <FeaturesContent image={grandexchange} rowDirection={`row`} description={<>Buy <span>NFT</span> items from other traders or sell items on the <span>Grand Exchange</span>, all in real-time.</>}/>
                <FeaturesContent image={farming} rowDirection={`row-reverse`} description={<>Provide liquidity in the farms to earn <span>$RSGP</span>, which can be spend on chests, the Grand Exchange and more. </>}/>
                <FeaturesContent image={gambling} rowDirection={`row`} description={<>Earn <span>rare items</span> in the form of an NFT, that all have their own unique multipliers to improve yield in the farms.</>}/>
                <FeaturesFooterText> <span>{"<< With much more things to come >>"}</span></FeaturesFooterText>
                <FeaturesContentsChangeText>Contents of this website are still subject to change.</FeaturesContentsChangeText>
              
              </FeaturesWrapper>
            </SectionFeatures>
          </MediaSectionFullWidth>
        </Section>
        <Section id="community">
          <MediaSectionCommunity>
            <SectionCommunity>
              <CommunityWrapper>
                <CommunityTextWrapper>
                  <SectionTitle>Community</SectionTitle>
                  <StyledDescription>Become part of our ever growing community, which can be found on the following platforms.</StyledDescription>
                </CommunityTextWrapper>
                <ContainerSocials>
                  <SocialsBox image={Twitter} url={`https://twitter.com/RSGPfinance`} platform={`Twitter`}/>
                  <SocialsBox image={Telegram} url={`https://t.me/RSGPfinance`} platform={`Telegram`}/>
                  <SocialsBox image={Discord} url={`https://discord.gg/53xqK7Gfjf`} platform={`Discord`}/>
                </ContainerSocials>
              </CommunityWrapper>
            </SectionCommunity>
          </MediaSectionCommunity>
        </Section>
    </Page>
  )
}

const FeaturesContentsChangeText = styled.div`
  position: absolute;
  margin-top: 20px;
  font-size: 15px;
  color: #8F8F8F;
  padding-right: 16px;
  text-decoration: none;
  text-align: center;
  bottom: -90px;
  width: 100%;
  font-family: 'RuneScape Plain 12', monotone;
  @media only screen and (min-width: 480px) {
    font-size: 17px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 18px;
  }
}
`

const HeaderInfoContainer = styled.div`
  margin: 0 auto 0 auto;
  height: 65%
`

const TheLogo = styled.img`
  width: 370px;

`

const MediaSectionCommunity = styled.div`

`

const MediaSectionFullWidth = styled.div`
  margin: 0 auto;
`

const MediaSectionTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 200px 8px 0px 8px;

  @media only screen and (min-width: 480px) {
    padding: 200px 16px 0px 16px;
  }

  @media only screen and (min-width: 768px) {
    padding: 200px 16px 0px 16px;
    min-height: 100vh;  
  }
`

const MediaSectionCentered = styled.div`
  margin: 0 auto;
  max-width: 900px;
`
const ContainerSocials = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media only screen and (min-width: 480px) {
    grid-gap: 15px;
    justify-content: center;
    flex-wrap: no-wrap;
  }
`

const CommunityTextWrapper = styled.div`
  text-align: center;
`

const CommunityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 120px 0;

  @media only screen and (min-width: 916px) {
      max-width: 900px;
  }
`

const SectionCommunity = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 70vh;
  padding: 0 8px;

  @media only screen and (min-width: 480px) {
    padding: 0 16px;
  }
`

const FeaturesFooterText = styled.span`
  text-align: center;
  color: #FFFF00;
  font-family: "RuneScape Plain 12";
  font-size: 17px;
  text-shadow: 0px 0px 30px #ffff004d;
  font-weight: bold;
  margin-top: 40px;

  @media only screen and (min-width: 480px) {
    font-size: 19px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 21px;
  }
`

const FeaturesWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 120px 0;
  grid-gap: 80px;

  @media only screen and (min-width: 768px) {
    align-items: center;
    width: 80%;
  }

  @media only screen and (min-width: 961px) {
    align-items: center;
    width: 70%;
  }
`

const SectionFeatures = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  background: black;
  padding: 0 8px;

  @media only screen and (min-width: 480px) {
    padding: 0 16px;
  }
`

const SectionTitle = styled.h4`
    font-size: 28px;
    font-family: 'RuneScape Plain 12', monotone;
    font-weight: bold;
    color: rgba(255, 176, 0, 1);
    text-shadow: 1px 1px black;
    margin: 0 0 20px 0;
    padding: 0;
    font-weight: 700;

    @media only screen and (min-width: 480px) {
      font-size: 32px;
    }
  
    @media only screen and (min-width: 768px) {
      font-size: 34px;
    }
`

const OverviewSection = styled.section`
  width: 100%;
  text-align: center;
  margin-top: 130px;
  margin-bottom: 30px;
  @media only screen and (min-width: 916px) {
      max-width: 1300px;
  }

  padding: 0 8px;

  @media only screen and (min-width: 480px) {
    padding: 0 16px;
  }
`

const ContainerBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  @media only screen and (min-width: 480px) {
    display: flex;
    justify-content: center;
  }

  @media only screen and (min-width: 768px) {
    gap: 15px;
  }
  
  @media only screen and (min-width: 1181px) {
    justify-content: space-between;
  }
`

const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 24px;
`

const ContainerText = styled.div`
  text-align: center;
  
  font-size: 18px;
  color: white;
  text-shadow: 1px 1px 0px rgb(0 0 0);
  font-family: "RuneScape Plain 12";
  line-height: 1;
  margin-bottom: 20px;
  & span {

  }

  & div {
    & span {
      color: #FFFF00;
      font-family: 'RuneScape Plain 12';
    }
  }

  @media only screen and (min-width: 480px) {
    & span {
      font-size: 19px;
    }
  }

  @media only screen and (min-width: 768px) {
    width: 626px;

    & span {
      font-size: 20px;
    }
  }
`

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: 768px) {
        width: 100%;
      }
`

const ContainerDescription = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    margin-bottom: 26px;
`

const ContainerStyledButton = styled.div`
    display: flex;
    gap: 10px;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
      }
`

const StyledTitle = styled.h4`
    font-size: 36px;
    font-family: "RuneScape Plain 12", monospace;
    color: rgba(255, 176, 0, 1);
    text-shadow: 0px 0px 30px  rgba(255, 176, 0, 0.6);
    margin: 0 0 20px 0;
    padding: 0;
    font-weight: 700;
`

const StyledDescription = styled.span`
  font-family: "RuneScape Plain 12", monospace;
  font-size: 16px;
  color: white;
  text-shadow: 0px 0px 30px  rgba(0, 0, 0, 1);
  text-align: center;

  @media only screen and (min-width: 480px) {
    font-size: 17px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 18px;
  }
`

const ButtonStyled = styled.a`
  align-items: center;
  background-color: #494034;
  border-radius: 1px;
  border: 1px solid rgba(5, 5, 4, 1);
  box-shadow: 3px 3px 1px 0px rgba(98, 85, 68, 0.31) inset;
  cursor: pointer;
  display: flex;
  text-decoration: none;

  height: 40px;
  justify-content: center;
  outline: none;
    padding: 0 12px;
  width: auto;//100%;
  &:hover {
    background-color: #354847;
  }
  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 768px) {
    &:last-child {
        margin-top: 10px;
    }
  }
`

const ContainerImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    margin-right: 12px;
`

const ButtonText = styled.span`
    font-size: 20px;
    color: rgba(255, 176, 0, 1);
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
    font-family: "RuneScape Plain 12", monospace;
`

const ButtonTogether = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5px;

  & a:nth-child(1), a:nth-child(2) {
    margin-right: 5px;
    margin-bottom: 5px;
  }

  @media only screen and (min-width: 480px) {
    font-size: 17px;
    flex-wrap: no-wrap;

    & a:nth-child(1), a:nth-child(2) {
      margin-bottom: unset;
    }
  }
`

const LinkedBtn = styled.a`

    align-items: center;
    background-color: #494034;
    border-radius: 1px;
    border: 1px solid rgba(5,5,4,1);
    box-shadow: 2px 2px 10px 0px rgb(96 86 74 / 80%) inset, -2px -2px 2px 0px rgb(0 0 0 / 60%) inset;
    color: rgba(255,176,0,1);
    cursor: pointer;
    text-shadow: 1px 1px 0px rgb(0 0 0);
    font-family: "RuneScape Plain 12", monocode;

    display: flex;
    font-size: 16px;
    height: 30px;
    justify-content: center;
    outline: none;
    padding-left: 24px;
    padding-right: 24px;
    width: auto;

    @media only screen and (min-width: 480px) {
      font-size: 17px;

    }

    @media only screen and (min-width: 768px) {
      font-size: 19px;
    }
}
`

export default Home