import React from 'react'
import styled from 'styled-components'

interface CardProps {
  height?: string,
  width?: string,
  title?: string,
  padding?: string
}

const MainContainer: React.FC<CardProps> = ({ children, title, height = undefined, width = undefined, padding = undefined}) => {

return (
  <StyledContainer
    height={height}
    width={width}
  >
    <ContainerHeader>
      <StyledTitle>{title}</StyledTitle>
    </ContainerHeader>
    <InnerContainer padding={padding}>
      {children}
    </InnerContainer>
  </StyledContainer>
)}
interface StyledContainerProps {
  height: string,
  width: string,
}

const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex-direction: column;

  height: ${(props) => props.height == undefined ? 'auto' : props.height};
  background: rgba(39,34,30,1);
  border: 4px solid rgb(27 23 22);

  width: 100%;

  @media only screen and (min-width: 916px) {
    max-width: ${(props) => props.width == undefined ? '900px' : props.width};
  }
`

interface InnerContainerProps {
  padding?: string
}

export const InnerContainerGrandExchange = styled.div<InnerContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 4px 0 4px;

  @media (min-width: 768px) {
    padding: ${(props) => props.padding === undefined ? '24px;' : props.padding};
  }
`

const InnerContainer = styled.div<InnerContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 4px;

  @media (min-width: 768px) {
    padding: ${(props) => props.padding === undefined ? '24px;' : props.padding};
  }
`

const ContainerHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31px;
  width: 100%;
  background: rgba(58,49,42,1);
  padding: 0px 8px;
  border-bottom: 2px solid rgba(65, 58, 53, 1);

  @media only screen and (min-width: 480px) {
    height: 34px;
  }

  @media only screen and (min-width: 600px) {
    height: 38px;
  }

  @media only screen and (min-width: 768px) {
    height: 41px;
  }
`

const StyledTitle = styled.span`
  font-size: 20px;
  color: rgba(255,176,0,1);
  text-shadow: 1px 1px 0px rgb(0 0 0);
  font-family: 'RuneScape Plain 12', montone;
    font-weight: bold;
  @media only screen and (min-width: 480px) {
    font-size: 22px;
  }

  @media only screen and (min-width: 600px) {
    font-size: 23px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 24px;
  }
`


const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  width: 100%;
  color: white;
  margin-top: 8px;
`

const NavigationTabs = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2px;
  padding-left: 12px;
  list-style: none;
  border-bottom: 1px solid #6d665f;
  width: 100%;
`

const NavButton = styled.button`
  display: block;
    padding: .5rem 1rem;
    color: #ff9f26;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    margin-bottom: -1px;
    background: 0 0;
    border: 2px solid #6d665f;
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem;
    cursor: pointer;
    outline: none;
    box-shadow: none;
    background: #30271c;
    text-decoration: none;
    box-shadow: 0px -1px 0px 1px rgb(48 39 28);
    margin-bottom: 1px;

    &:hover {
      color: ${(props) => props.theme.color.green[500]};
    }
    &.active {
      background-color: #27221e;
      border-color: #6d665f #6d665f #27221e;
      margin-bottom: -2px;
      padding-bottom: 12px;
    }
`

export default MainContainer
