import React, { useCallback, useState, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import './Nav.scss'
import { slide as Menu } from 'react-burger-menu'

// make a new context
export const MyContext = React.createContext(Menu);

// create the provider
export const MyProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false)
  
  return (
    <MyContext.Provider value={{
      isMenuOpen: menuOpenState,
      toggleMenu: () => {setMenuOpenState(!menuOpenState);},
      stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen)
    }}>
      {props.children}
    </MyContext.Provider>
  )
}

// create a button that calls a context function to set a new open state when clicked
const StyledLinkBtn = (props) => {
  const ctx = useContext(MyContext)
  return (
    <StyledLink exact activeClassName="active menu-item" to={props.to} onClick={ctx.toggleMenu}>{props.children}</StyledLink>
  )
}

// create a button that calls a context function to set a new open state when clicked
const Hamburger = () => {
  const ctx = useContext(MyContext)
  return (
    <button onClick={ctx.toggleMenu}>hamburger</button>
  )
}

// create a navigation component that wraps the burger menu
const MobileMenu = () => {
    const ctx = useContext(MyContext)
  
    return (
      <Menu 
        styles={ styles } 
        width={'240px'}
        right
        customBurgerIcon={false}
        isOpen={ctx.isMenuOpen}
        onStateChange={(state) => ctx.stateChangeHandler(state)}
      >
        <StyledLinkBtn to={"/"}>
          Home
        </StyledLinkBtn>
        <StyledLinkBtn to={"/farms"}>
          Farms
        </StyledLinkBtn>
        <StyledLinkBtn to={"/grandexchange"}>
          Grand Exchange
        </StyledLinkBtn>
        <StyledLinkBtn to={"/gambling"}>
          Gambling
        </StyledLinkBtn>
        </Menu>
    )
  }

var styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      left: '36px',
      top: '36px'
    },
    bmBurgerBars: {
      background: '#373a47'
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: '#bdc3c7'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%',
      top: '0',
      transition: 'all 0.3s ease 0s'
    },
    bmMenu: {
      background: 'rgba(62,53,45,1)',
      padding: '2.5em 1.5em 0',
      boxShadow: '-1px -1px 0px 0px rgb(16 14 12)'
    },
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItemList: {
      color: '#b8b7ad',
      padding: '0.8em',
      display: 'flex',
      lineHeight: '50px',
      flex: '1',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      fontSize: '18px'
    },
    bmItem: {
      display: 'inline-block'
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
  }
  
  const StyledLink = styled(NavLink)`
    color: ${(props) => props.theme.color.white};
    font-weight: 700;
    padding-left: ${(props) => props.theme.spacing[2]}px;
    padding-right: ${(props) => props.theme.spacing[2]}px;
    text-decoration: none;
    &:hover {
      color: ${(props) => props.theme.color.green[500]};
    }
    &.active {
      color: #FFB000;;
    }
    @media (max-width: 400px) {
      padding-left: ${(props) => props.theme.spacing[2]}px;
      padding-right: ${(props) => props.theme.spacing[2]}px;
    }
  `
  
  const StyledAbsoluteLink = styled.a`
    color: ${(props) => props.theme.color.white};
    font-weight: 700;
    padding-left: ${(props) => props.theme.spacing[2]}px;
    padding-right: ${(props) => props.theme.spacing[2]}px;
    text-decoration: none;
    outline: none;
    &:hover {
      color: ${(props) => props.theme.color.green[500]};
    }
    &.active {
      color: ${(props) => props.theme.color.red[800]};
    }
    @media (max-width: 400px) {
      padding-left: ${(props) => props.theme.spacing[2]}px;
      padding-right: ${(props) => props.theme.spacing[2]}px;
    }
  `
  
  export default MobileMenu
  
