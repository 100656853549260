import React, {useCallback, useEffect, useState} from 'react'
import {HashRouter as Router, Route, Switch} from 'react-router-dom'
import DisclaimerModal from './components/DisclaimerModal'
/*import MobileMenu from './components/MobileMenu'*/
import TopBar from './components/TopBar'
import useModal from './hooks/useModal'
import theme from './theme'
import Home from './views/Home'
import MobileMenu, {MyProvider } from './components/TopBar/components/MobileMenu'
import {ThemeProvider} from 'styled-components'
import { ScrollingProvider } from 'react-scroll-section';

const App: React.FC = () => {
    return (
        <ScrollingProvider>
            <ThemeProvider theme={theme}>
                <Disclaimer/>
                <Router>
                    <MyProvider>
                        <MobileMenu/>
                        <TopBar/>
                    </MyProvider>
                    <Switch>
                        <Route path="/" exact>
                            <Home />
                        </Route>
                    </Switch>
                </Router>
            </ThemeProvider>
        </ScrollingProvider>
    )
}

const Disclaimer: React.FC = () => {
    const markSeen = useCallback(() => {
        localStorage.setItem('disclaimer', 'seen')
    }, [])

    const [onPresentDisclaimerModal] = useModal(
        <DisclaimerModal onConfirm={markSeen}/>,
    )

    useEffect(() => {
        const seenDisclaimer = localStorage.getItem('disclaimer')
        if (!seenDisclaimer) {
            onPresentDisclaimerModal()
        }
    }, [])

    return <div/>
}

export default App
