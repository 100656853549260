import { black, green,blue, grey, red, white, text } from './colors'

const theme = {
  borderRadius: 5,
  breakpoints: {
    mobile: 768,
  },
  color: {
    black,
    grey,
    primary: {
      light: red[100],
      main: red[500],
    },
    secondary: {
      main: green[500],
    },
    white,
    green,
    blue,
    red,
    text
  },
  siteWidth: 1200,
  spacing: {
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 48,
    7: 64,
  },
  buttonWidth: {
    10: 10,
    20: 20,
    30: 30,
    40: 40,
    50: 50,
    60: 60,
    70: 70,
    80: 80,
    90: 90,
    100: 100,
  },
  topBarSize: 72
}

export default theme
