import React, {useState,useEffect} from 'react'
import styled from 'styled-components'
import {HashRouter as Router, Route, Switch} from 'react-router-dom'
import { NavLink } from 'react-router-dom'

const Nav: React.FC = () => {
  return (
    <StyledNav>
      <InfoText

      >
        Disclaimer: This project is not affiliated with or sponsored by Jagex Ltd. or RuneScape in any way. 
      </InfoText>
    </StyledNav>
  )
}

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
  justify-content: center;
`

const StyledLink = styled(NavLink)`
  font-size: 18px;
  color: ${(props) => props.theme.color.grey[1000]};
  padding-right: ${(props) => props.theme.spacing[3]}px;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.color.green[500]};
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

const InfoText = styled.span`
text-align: center;
  font-size: 18px;
  color: ${(props) => props.theme.color.green[500]};
  text-shadow: 1px 1px 0px rgb(0 0 0);
  width: 80%;
  @media (min-width: 768px) {
    width: 100%;

  }
`

const StyledAbsoluteLink = styled.a`
  font-size: 18px;
  color: ${(props) => props.theme.color.grey[1000]};
  padding-right: ${(props) => props.theme.spacing[3]}px;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.color.green[500]};
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

export default Nav
