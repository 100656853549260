import React from 'react'
import styled from 'styled-components'
import Footer from '../Footer'

const Page: React.FC = ({ children }) => (
  <StyledPage>
    <StyledMain>{children}</StyledMain>
    <Footer/>
  </StyledPage>
)

const StyledPage = styled.div``

const StyledMain = styled.div`
  min-height: calc(100vh - ${(props) => ((props.theme.topBarSize * 3) / 2) + 32}px);
  /*padding: 0 8px;*/

  @media only screen and (min-width: 916px) {
    padding: 0;
    min-height: calc(100vh - ${(props) => (props.theme.topBarSize * 3) +6}px);
  }
` 

export default Page
